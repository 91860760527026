/// Operations on dates

import { DateTime } from "luxon";

import type { DateRange } from "@/data/common.types";

/** Given a date, returns midnight on the next Sunday */
const roundUpDate = (date: Date): Date => {
  const diff = 7 - date.getDay();
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + diff,
    23,
    59,
    59
  );
};
/** Given a date, returns that exact time one week prior */
const oneWeekPrev = (date: Date): Date => {
  const d = new Date(date);
  d.setDate(d.getDate() - 7);
  return d;
};

const inThisWeek = (d: Date, w: Date): boolean => {
  const diff = roundUpDate(d).getTime() - w.getTime();
  return diff >= 0 && diff < 7 * 24 * 60 * 60 * 1000;
};

const isInRange = (d: Date, range?: DateRange): boolean => {
  if (!range || !d) return true;
  const afterFrom = range.from
    ? new Date(d).getTime() >= new Date(range.from).getTime()
    : true;
  const beforeTo = range.to
    ? new Date(d).getTime() <= new Date(range.to).getTime()
    : true;
  return afterFrom && beforeTo;
};

const convertDateRange = (dateRange: DateRange) => {
  return dateRange.from && dateRange.to
    ? `${DateTime.fromJSDate(dateRange.from).toFormat(
        "dd LLL yyyy"
      )} - ${DateTime.fromJSDate(dateRange.to).toFormat("dd LLL yyyy")}`
    : "";
};

/**
 * Get the start of the week provided
 * @param weeknum consecutively numbered week in a year in format YYYY/ww
 * @returns a JS Date object representing the start of that week.
 */
const toWeekStart = (weeknum?: string): Date | undefined => {
  if (!weeknum) return;
  // weeknum is in the format "YYYY/ww" where "ww" is the consecutive numbered week of the year.
  const [year, week] = weeknum.split("/").map((n) => Number.parseInt(n));
  if (!year || !week) return;
  const jan1 = DateTime.fromObject({
    year,
    month: 1,
    day: 1,
  });
  // if (year === 2022) console.log("Jan 1:", jan1.toJSDate());
  const startOfFirstWeek =
    jan1.weekday !== 7 ? jan1.minus({ days: jan1.weekday }) : jan1;
  // if (year === 2022)
  //   console.log("Start of first week:", startOfFirstWeek.toJSDate());
  const date = startOfFirstWeek.plus({ days: (week - 1) * 7 }).toJSDate();
  return date;
};

const DateOps = {
  roundUpDate,
  oneWeekPrev,
  inThisWeek,
  isInRange,
  convertDateRange,
  toWeekStart,
};

export default DateOps;
