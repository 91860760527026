import { queryOptions } from "@tanstack/react-query";

import api from "@/api/client";
import type {
  StripeCheckoutPostDto,
  StripePortalPostDto,
} from "@/api/generated";

import { cancelableQuery, mutationOptions } from "./helpers";

export const stripeKeys = {
  root: "stripe" as const,
  customer: () => [stripeKeys.root, "customer" as const] as const,
};

const stripeQueryApi = {
  createCheckoutSession: mutationOptions({
    mutationFn: (data: StripeCheckoutPostDto) =>
      api.stripe.postApiStripeCheckoutSession(undefined, data),
  }),

  createPortalSession: mutationOptions({
    mutationFn: (data: StripePortalPostDto) =>
      api.stripe.postApiStripePortalSession(undefined, data),
  }),

  getStripeCustomer: queryOptions({
    queryFn: ({ signal }) =>
      cancelableQuery(api.stripe.getApiStripeCustomer(), signal),
    queryKey: stripeKeys.customer(),
  }),
};

export default stripeQueryApi;
