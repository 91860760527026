import { useQuery } from "@tanstack/react-query";

import { useParamsFilter } from "@/context/filter";
import { teamQueryApi } from "@/data/api/teams";

interface IUseMediaList {
  enabled?: boolean;
  teamId: string;
}

export default function useMediaList(props: IUseMediaList) {
  const { urlFilters: urlParams } = useParamsFilter();
  const { teamId, enabled } = props;
  return useQuery({
    ...teamQueryApi.getTeamMediaList(teamId, {
      bbox: urlParams.bbox ?? undefined,
      dateString: urlParams.dateString,
      dateStringEnd: urlParams.dateStringEnd,
      dayOnly: urlParams.dayOnly,
      finishedOnly: urlParams.finishedOnly,
      includeEmpty: urlParams.includeEmpty,
      is360: urlParams.is360,
      publishedOnly: urlParams.publishedOnly,
      sort: urlParams.sort,
      search: urlParams.search,
      tags: urlParams.tags,
      tagMode: urlParams.tagMode,
      types: urlParams.types ? [urlParams.types] : undefined,
      userIds: urlParams.userIds,
    }),
    enabled,
  });
}
